<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 mb-3 text-center font19" >
      Review your Donation
    </div>
    <div class="col-12 mb-3 text-center" >
      <div class="row mx-0 justify-content-center">
        <div class="col-sm-10 col-md-7 col-xl-6 p-0 card_item overflow">
          <DonorSummary
            :user="user"
            :sadaqahUserDonatingType="sadaqahUserDonatingType"
            :anonymous="anonymous"
            :donatingAs="donatingAs"
            @goTo="goToDonor"
          />
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center divider1">
            </div>
          </div>
          <PaymentSummary
            :paymentMethod="paymentMethod"
            :paymentFrequencyDetails="paymentFrequencyDetails"
            :bankAccountDetails="bankAccountDetails"
            @goTo="goToPayment"
          />
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center divider1">
            </div>
          </div>
          <div class="row mx-0 justify-content-center text-center">
            <div class="col-12 green-text-dark font12 bold mb-1 pt-2 pb-4">
              DAILY AMOUNT
            </div>
            <div class="col-12 text-center position-relative">
              <Button color="gold" :btnText="totalDisplay" class="position-absolute start-50 translate-middle abs font20 text-nowrap lh-1 position-absolute top-100 zindex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation Summary',
  props: ['user', 'anonymous', 'paymentMethod', 'paymentFrequencyDetails', 'sadaqahUserDonatingType', 'totalDailySadaqah', 'donatingAs', 'bankAccountDetails'],
  emits: ['goToPayment', 'goToDonor'],
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    DonorSummary: defineAsyncComponent(() => import('./DonorSummary.vue')),
    PaymentSummary: defineAsyncComponent(() => import('./PaymentSummary.vue'))
  },
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  computed: {
    totalDisplay () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.totalDailySadaqah * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  methods: {
    goToPayment () {
      this.$emit('goToPayment')
    },
    goToDonor () {
      this.$emit('goToDonor')
    }
  }
}
</script>
<style scoped>
.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.overflow {
  overflow: visible;
}
.divider1 {
  border-bottom: 1px solid var(--green-color-dark);
}
</style>
